import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ItUsersAuthComponent } from './it-auth/it-auth.component';
import { ItUsersAuthGuard } from './it-users-auth.guard';
import { ItUsersOperationsComponent } from './it-operations/it-operations.component';
import { ItUsersFlagGuard } from './it-users-feature-flag.guard';

const routes: Routes = [
  {
    path: 'it-frontend-it-users-only-use-with-cautious',
    canActivate: [ItUsersFlagGuard],
    component: ItUsersAuthComponent,
  },
  {
    path: 'it-frontend-it-users-only-use-with-cautious/it-frontend-operations',
    canActivate: [ItUsersAuthGuard, ItUsersFlagGuard],
    component: ItUsersOperationsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ItUsersRoutingModule {}
