import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { FeatureFlagService } from '@codenteam/core/feature-flags/frontend';
import { tap } from 'rxjs';

export const ItUsersFlagGuard: CanActivateFn = () => {
  const featureFlagService = inject(FeatureFlagService);
  const request = featureFlagService.getFlag$('it-operations');
  return request.pipe(
    tap((res) => {
      return res;
    })
  );
};
