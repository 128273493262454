<div class="flex min-h-screen flex-col items-center justify-center px-5 py-10">
  <div class="w-full max-w-md rounded-2xl bg-white p-6 shadow-lg">
    <h2 class="mb-4 text-center text-2xl font-bold text-gray-700">
      Add UUID and Choose an Operation
    </h2>

    <!-- Select Operation -->
    <div class="mb-4">
      <label
        for="operation"
        class="mb-2 block text-sm font-medium text-gray-600"
        >Select Operation</label
      >
      <select
        id="operation"
        [(ngModel)]="selectedOperation"
        class="w-full rounded-lg border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        (change)="onOperationChange()"
      >
        <option
          *ngFor="let operation of operations"
          [value]="operation"
        >
          {{ operation }}
        </option>
      </select>
    </div>

    <!-- Input Field for UUID (Enabled for Specific Operations) -->
    <div
      class="mb-4"
      *ngIf="isUuidInputEnabled"
    >
      <label
        for="uuid"
        class="mb-2 block text-sm font-medium text-gray-600"
        >Add UUID</label
      >
      <div class="flex">
        <input
          id="uuid"
          type="text"
          [(ngModel)]="newUuid"
          class="flex-1 rounded-l-lg border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter UUID"
        />
        <button
          (click)="addUuid()"
          class="rounded-r-lg bg-blue-500 px-4 text-white hover:bg-blue-600"
        >
          Add
        </button>
      </div>
    </div>

    <!-- Display Added UUIDs -->
    <div
      *ngIf="runsUuids.length"
      class="mb-4"
    >
      <p class="text-sm font-medium text-gray-600">Added UUIDs:</p>
      <ul class="mt-2 list-disc pl-5 text-sm text-gray-700">
        <li *ngFor="let uuid of runsUuids">{{ uuid }}</li>
      </ul>
    </div>

    <button
      (click)="executeOperation()"
      class="w-full rounded-lg bg-blue-500 py-2 font-medium text-white transition-colors hover:bg-blue-600"
    >
      Execute
    </button>
  </div>

  <div
    *ngIf="operationResult"
    class="mt-6 w-full max-w-md rounded-lg border border-green-300 bg-green-100 p-4 text-green-700"
  >
    <p class="font-medium">Operation Result:</p>
    <p>{{ operationResult }}</p>
  </div>

  <button
    (click)="logout()"
    class="mt-6 rounded-lg bg-red-500 px-6 py-2 font-medium text-white transition-colors hover:bg-red-600"
  >
    Logout
  </button>
</div>
