<div
  *ngIf="initialLoading"
  class="flex w-full justify-around"
>
  <mat-spinner
    diameter="100"
    class="w-full"
  >
  </mat-spinner>
</div>

<form
  class="mx-auto mt-40 w-full max-w-xl"
  *ngIf="!initialLoading"
  @fade
  (ngSubmit)="onSubmit()"
>
  <div class="!mx-10 md:mx-auto">
    <div class="mb-3 mt-3 flex">
      <mat-icon class="text-blue-200">person</mat-icon>
      <input
        data-test="email-input"
        type="email"
        class="ml-4 w-full border-b-2 border-l-0 border-r-0 border-t-0 border-slate-900 bg-slate-800 pb-1 placeholder-gray-400 !outline-none focus:bg-slate-800 dark:text-white"
        name="email"
        placeholder="Your Email"
        [(ngModel)]="email"
        required
      />
    </div>

    <div class="mb-3 flex">
      <mat-icon class="text-blue-200">lock</mat-icon>
      <input
        data-test="password-input"
        type="password"
        class="ml-4 w-full border-b-2 border-l-0 border-r-0 border-t-0 border-slate-900 bg-slate-800 pb-1 placeholder-gray-400 !outline-none focus:bg-slate-800 dark:text-white"
        name="password"
        placeholder="Your Password"
        [(ngModel)]="password"
        required
      />
    </div>

    <div class="flex w-full">
      <button
        data-test="login-button"
        type="submit"
        class="font-mada !ml-auto mt-4 !w-32 cursor-pointer rounded-md border-none bg-rose-500 py-1 text-center text-lg font-normal text-white dark:bg-rose-500"
      >
        Log In
        <mat-spinner
          diameter="20"
          *ngIf="loading"
        ></mat-spinner>
      </button>
    </div>
  </div>
</form>
