import { Component } from '@angular/core';
import { ITService } from '../it-users.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'codenteam-it-users',
  templateUrl: './it-auth.component.html',
  styleUrls: ['./it-auth.component.scss'],
})
export class ItUsersAuthComponent {
  constructor(
    private itService: ITService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  initialLoading = false;
  loading = false;
  email = '';
  password = '';
  errorMessage = '';

  onSubmit() {
    this.loading = true;
    this.errorMessage = '';

    this.itService.login(this.email, this.password).subscribe({
      next: (response) => {
        this.showSnackbar('Login Successful!', 'success');
        this.router.navigate([
          '/it-frontend-it-users-only-use-with-cautious/it-frontend-operations',
        ]);
      },
      error: (error) => {
        this.errorMessage = error.error.message || 'Login Failed';
        this.showSnackbar(this.errorMessage, 'error');
      },
    });
    this.loading = false;
  }

  private showSnackbar(message: string, type: 'success' | 'error') {
    this.snackBar.open(message, 'Close', {
      duration: 2000,
      panelClass: type === 'success' ? 'snackbar-success' : 'snackbar-error',
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}
