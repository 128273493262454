import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root',
})
export class ITService {
  constructor(
    @Inject('APP_SETTINGS') private appSettings: AppSettings,
    private http: HttpClient
  ) {}

  private readonly baseUrl = `${this.appSettings.apiPath}/it-api-it-users-only-use-with-cautious`;

  isItUserLoggedIn() {
    return this.http.get(`${this.baseUrl}/info`, {
      withCredentials: true,
    });
  }

  login(email: string, password: string) {
    const url = `${this.baseUrl}/authenticate`;
    const body = { email, password };

    return this.http.post(url, body, {
      withCredentials: true,
    });
  }

  logout() {
    const url = `${this.baseUrl}/logout`;
    return this.http.post(url, {
      withCredentials: true,
    });
  }

  performOperation(operation: string, runUuids: string[]) {
    const url = `${this.baseUrl}/operations/${operation}`;
    return this.http.post(url, { runUuids });
  }
}
