import { Component } from '@angular/core';
import { ITService } from '../it-users.service';
import { Router } from '@angular/router';

enum AllowedOperations {
  SbomRecalculation = 'sbom-recalculation',
  RisksRecalculation = 'risks-recalculation',
  UpdateLicensesInDB = 'update-licenses',
  LoopOnAllLicensesAndAddSlugAndBaseName = 'loop-on-all-licenses-and-add-slug-and-base-name',
  RedoAllExtractingVersionedDependencyNameFromPurl = 'redo-all-extracting-versioned-dependency-name-from-purl',
  // redoAllMergingLicensesWithTheSameSlug = 'redo-all-merging-licenses-with-the-same-slug',
  ClearRunCache = 'clear-run-cache',
  MergeDuplicateLicensesWithSameName = 'merge-duplicate-licenses-with-same-name',
}

@Component({
  selector: 'codenteam-operations',
  templateUrl: './it-operations.component.html',
  styleUrl: './it-operations.component.scss',
})
export class ItUsersOperationsComponent {
  operations = Object.values(AllowedOperations);
  selectedOperation: string = this.operations[0];
  operationResult: string | null = null;
  runsUuids: string[] = [];
  newUuid = '';
  isUuidInputEnabled = false;

  constructor(private itService: ITService, private router: Router) {
    this.onOperationChange();
  }

  onOperationChange() {
    this.isUuidInputEnabled =
      this.selectedOperation === AllowedOperations.SbomRecalculation ||
      this.selectedOperation === AllowedOperations.RisksRecalculation ||
      this.selectedOperation === AllowedOperations.ClearRunCache;
  }

  addUuid() {
    if (this.newUuid.trim()) {
      this.runsUuids.push(this.newUuid.trim());
      this.newUuid = '';
    }
  }

  executeOperation() {
    if (!this.selectedOperation) return;

    const uuidsToSend =
      this.isUuidInputEnabled && this.runsUuids.length > 0
        ? this.runsUuids
        : null;

    this.itService
      .performOperation(this.selectedOperation, uuidsToSend)
      .subscribe({
        next: (response: any) => {
          this.operationResult = response.message;
        },
        error: (error) => {
          this.operationResult = `Error: ${error.message}`;
        },
      });
  }

  logout() {
    this.itService.logout().subscribe(() => {
      this.router.navigate(['/it-frontend-it-users-only-use-with-cautious']);
    });
  }
}
