import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, map, of, shareReplay } from 'rxjs';
import { ITService } from './it-users.service';

export const ItUsersAuthGuard: CanActivateFn = () => {
  const itUsersAuthService = inject(ITService);
  const router = inject(Router);
  const request = itUsersAuthService.isItUserLoggedIn().pipe(shareReplay());
  const result = request.pipe(
    map((res) => {
      if (res && res !== null) {
        return true;
      } else {
        router.navigate(['/it/users']);
        return false;
      }
    }),
    catchError((err) => {
      router.navigate(['/it/users']);
      return of(false);
    })
  );
  return result;
};
